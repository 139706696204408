.license-new {
  -webkit-box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    padding: 0 !important;
    label {
      height: 30px !important;
    }
  }

  .license-header {
    border-radius: 8px 8px 0px 0px;
    background: #F8F8F8;
    padding: 18px 10px;
    z-index: 10;
  }
  .tab-bar {
    background: white;
    border-right: 8px;
    .ant-tabs-nav:before {
      border-bottom: 0;
    }
  }

  .members {
    max-height: 40vh;
    overflow: scroll;
    padding: 1.6rem;
  }

  .ant-tabs-tabpane, .ant-tabs-content-holder {
    background: #f8f9fb;
    min-height: 40vh;
    padding: 0 !important;
    //border-left: 1px solid #ededed;
  }

  .ant-tabs-tab {
    margin: 0px 15px !important;
    //margin-bottom: 8px !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .license-cards {
    border-radius: 0px 0px 8px 8px;
    max-height: 50vh;
    overflow: hidden;
    background: white;

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }

    .license-card {
      border-radius: 0;
      cursor: pointer;
      transition: background 0.3s ease-in;

      &:hover {
        background: #f6f6f6;
      }
    }
  }
}
