.home-menu {
  -webkit-box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 25px -1px rgba(0, 0, 0, 0.15);

  .home-header {
    background: #F8F8F8;
    padding: 18px 10px;
    z-index: 10;
    border-radius: 8px 8px 0 0;
  }

  .user-avatar {
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .home-list {
    max-height: 50vh;
    overflow: auto;

    .home-card {
      cursor: pointer;
      transition: background 0.3s ease-in;
      text-align: left;
      &:hover {
        background: #f6f6f6;
      }
    }
  }
}
