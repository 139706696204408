@import "./tailwind.output.css";

html, body {
  font-family: "ArialMT", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

html, body, #root {
  height: 100%;
}

.logoContainer {
  text-align: center;
  margin: 0 auto;
  padding: 20px 0;
}

.logo {
  max-width: 100% !important;
  width: 320px;
}

.logoFooter {
  max-width: 100% !important;
  width: 120px;
}

//REMOVE CHROME AUTOCOMPLETE FILL BACKGROUND
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//ADAPT CHROME AUTOCOMPLETE FONT SIZE
input:-webkit-autofill::first-line {
  font-size: 16px !important;
}

.cursor-pointer {
  cursor: pointer;
}

thead[class*="ant-table-thead"] th{
  font-size: 12px;
  font-weight: bold;
  padding: .7rem 1rem;
}
