.wrapperCentered {
  background: #fbfbfb;
  width: 100%;
  min-height: 100%;
  display: flex;
  //align-items: center;
  justify-content: center;
  background: url('../assets/img/bg.svg') center center / cover no-repeat;
  background-attachment: fixed;
  transition: 1s ease-in background;
  -webkit-transition: 1s ease-in background;
  -moz-transition: 1s ease-in background;

  .container {
    width: 100%;
    padding: 0 20px;
    margin-top: 15vh;
    margin-bottom: 15vh;
    max-width: 680px;
  }
  &.large {
    .container {
      max-width: 800px !important;
    }
  }
}
