.auth-signin {
  .logo {
    max-height: 120px;
    margin-bottom: .6rem;
  }

  .auth-card {
    transition: all 0.5s;
    -webkit-box-shadow: 0px 12px 10px -9px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 12px 10px -9px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 12px 10px -9px rgba(0, 0, 0, 0.15);
  }

  //.signin-card:hover {
  //  transition: all 0.5s;
  //  -webkit-box-shadow: 0px 15px 25px -9px rgba(0, 0, 0, 0.15);
  //  -moz-box-shadow: 0px 15px 25px -9px rgba(0, 0, 0, 0.15);
  //  box-shadow: 0px 15px 25px -9px rgba(0, 0, 0, 0.15);
  //}

  .ant-tabs {
    .ant-tabs-tab {
      margin: 0 15px !important;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  .halfwidth {
    min-width: 150px;
  }

  .ant-form-item {
    margin-bottom: .8em;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }
}
